import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Box, Flex } from "@chakra-ui/react";
import SEO from "../components/seo";
import Products from "../components/Products";
import Nav from "../components/Nav";
import { translate } from "../utils/get-locale";

const SingleProductTitle = styled.div`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: ${(props) => props.size}rem;
	line-height: 1;
	color: ${(props) => props.color};
	text-decoration: none;
	padding: 0 0 1rem;
`;

const DescriptionText = styled.p`
	font-family: "Poppins";
	font-weight: 700;
	font-style: normal;
	font-size: ${(props) => props.size}rem;
	line-height: 1.3;
	color: ${(props) => props.color};
	padding-top: 2rem;
`;

const ProductSubheaderWrapper = styled.div`
	margin: 2.625rem 0 3.25rem;
	h2 {
		font-family: "Poppins";
		font-weight: 700;
		font-style: normal;
		font-size: 2.25rem;
		color: ${(props) => props.color};
	}
`;

const RecyclingTitle = styled.h3`
	font-family: "Poppins";
	font-weight: 700;
	font-style: normal;
	font-size: 1rem;
	color: ${(props) => props.color};
`;

export default function SingleProductPage({ data: { product } }) {
	const breakpoints = useBreakpoint();
	const productData = product.productData;
	const bgcolor = productData.productBgcolor
		? productData.productBgcolor
		: "#000";
	const textcolor = productData.productBgcolor ? "#fff" : "#000";
	//console.log(productData.productName);
	return (
		<>
			<SEO
				title={product.seo.title}
				description={product.seo.metaDesc}
				image={
					productData.productImage.localFile.childImageSharp.gatsbyImageData
				}
			/>
			<Nav />
			<Box pb={["2rem", "5rem"]}>
				<Box width="100%" backgroundColor={bgcolor}>
					<Box maxW={980} width="100%" px="16px" mx="auto" mt="8rem">
						<Flex
							pos="relative"
							direction={["column", "row"]}
							backgroundColor={bgcolor}
						>
							<Box
								position={["relative", "relative"]}
								w={["60%", "40%"]}
								mx="auto"
							>
								<Box
									position={["relative", "absolute"]}
									width={["auto", "80%"]}
									ml={["auto", "1rem"]}
									maxHeight={500}
								>
									<GatsbyImage
										image={
											productData.productImage.localFile
												.childImageSharp.gatsbyImageData
										}
										style={{ overflow: "visible" }}
										imgStyle={{
											marginTop: "-5rem",
											objectFit: "contain",
											maxHeight: 500,
										}}
									/>
								</Box>
							</Box>
							<Box
								w={["100%", "60%"]}
								mt={["-4rem"]}
								padding={["1rem 2rem 3rem", "4rem 2rem 0 0"]}
							>
								<Box py="4rem">
									<SingleProductTitle
										color={textcolor}
										size={breakpoints.sm ? 3 : 3.25}
									>
										{productData.productNameHyphenated
											? productData.productNameHyphenated.replace(/-/g, '\u00AD')
											: productData.productName}
									</SingleProductTitle>
									<SingleProductTitle
										color={textcolor}
										size={breakpoints.sm ? 1.5 : 1.85}
									>
										{productData.productSubtitle}
									</SingleProductTitle>
									<DescriptionText
										color={textcolor}
										size={breakpoints.sm ? 1.5 : 2}
									>
										{productData.productDescription}
									</DescriptionText>
								</Box>
							</Box>
						</Flex>
					</Box>
				</Box>

				<Box maxW={980} width="100%" px="16px" mx="auto" mb="3rem">
					<Box pl={[0, "40%"]} className="product-content-wrapper">
						<ProductSubheaderWrapper color={bgcolor}>
							<h2>{translate("kayttoohjeet")}</h2>
						</ProductSubheaderWrapper>
						<Flex direction={["column", "row"]}>
							<Box width={["100%", "50%"]} pl={0}>
								<ol>
									{productData.productInstructions?.map(
										(item, i) => (
											<li key={`list-${i}`}>
												{Object.values(item)}
											</li>
										)
									)}
								</ol>
							</Box>
							<Box
								width={["100%", "50%"]}
								mt={["2rem", 0]}
								pl={[0, "1rem"]}
								className="product-content-instructions"
							>
								<ul>
									{productData.productFacts?.map(
										(item, i) => (
											<li key={`facts-${i}`}>
												{Object.values(item)}
											</li>
										)
									)}
								</ul>
								<div className="product-recycling-subheader-wrapper">
									<RecyclingTitle color={bgcolor}>
									{translate("kierratysohjeet")}
									</RecyclingTitle>
								</div>
								<p>{productData.productRecycling}</p>
							</Box>
						</Flex>
					</Box>
				</Box>

				<Products locale={product.locale.locale} />
			</Box>
		</>
	);
}

export const query = graphql`
	fragment Thumbnail on File {
		childImageSharp {
			fluid(maxWidth: 555) {
				...GatsbyImageSharpFluid
			}
		}
	}

	query Product($slug: String!) {
		product: wpProduct(slug: { eq: $slug }) {
			title
			slug
			seo {
				metaDesc
				title
			}
			locale {
				locale
			}
			productData {
				productName
				productNameHyphenated
				productSubtitle
				productBgcolor
				productDescription
				productInstructions {
					productInstructionsItem
				}
				productFacts {
					productFactsItem
				}
				productRecycling
				productSubCategory
				productMainCategory
				productImage {
					localFile {
						childImageSharp { 
							gatsbyImageData(width: 555)
						}
					}
				}
			}
		}
	}
`;
