import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import CheckIcon from "../assets/svg/checkred.inline.svg";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { getLocale, getLocaleUrl, translate } from "../utils/get-locale";

const StyledLink = styled(Link)`
	display: flex;
	flex-direction: column;
	height: 100%;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
`;
const ProductTitle = styled.h3`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: ${(props) => props.size}rem;
	line-height: 1;
	text-align: center;
	color: #154734;
	text-decoration: none;
	padding: 1rem 0;
`;

const ProductSubTitle = styled.div`
	font-family: "Poppins";
	font-weight: 300;
	font-style: normal;
	font-size: ${(props) => props.size}rem;
	line-height: 1;
	text-align: center;
	color: #154734;
	text-decoration: none;
`;

const getUnique = (items, value) => {
	const categories = items.map((item) => item.node.productData[value]).flat();
	return [...new Set(categories.map((item) => item))];
};

const CustomRadio = React.forwardRef((props, ref) => {
	const { selected, onChange, value, color } = props;
	return (
		<Flex
			alignItems="center"
			mx="5px"
			ref={ref}
			cursor="pointer"
			onClick={() => {
				onChange(value);
			}}
		>
			<Box
				pos="relative"
				w="22px"
				h="22px"
				pt="3px"
				pl="2px"
				rounded="11px"
				borderColor={color}
				borderWidth="1px"
				borderStyle="solid"
				aria-checked={selected}
				role="radio"
			>
				{value === selected ? <CheckIcon /> : null}
			</Box>
			<Text
				pl="5px"
				fontSize="0.86rem"
				fontFamily="Poppins"
				fontWeight="900"
				color="#00358e"
			>
				{translate(value.toLowerCase())}
			</Text>
		</Flex>
	);
});

export default function Products({ locale }) {
	const data = useStaticQuery(
		graphql`
			query queryProducts {
				queryProducts: allWpProduct {
					edges {
						node {
							slug
							locale {
								locale
							}
							productData {
								productName
								productNameHyphenated
								productSubtitle
								productImage {
									localFile {
										childImageSharp { 
											gatsbyImageData(height: 430)
										}
									}
								}
								productMainCategory
								productSubCategory
							}
						}
					}
				}
			}
		`
	);

	const breakpoints = useBreakpoint();
	const [selected, setSelected] = useState("Kaikki");
	const [products, setProducts] = useState(
		data.queryProducts.edges.filter(
			(item) => item.node.locale.locale === locale
		)
	);
	const [productsToShow, setProductsToShow] = useState(
		data.queryProducts.edges.filter(
			(item) => item.node.locale.locale === locale
		)
	);

	const mains = getUnique(data.queryProducts.edges, "productMainCategory");
	//const subs = getUnique(allProducts.edges, "productSubCategory");

	const mainSelects = ["Kaikki", ...mains];

	const doFiltering = (val) => {
		setSelected(val);
		if (val === "Kaikki") {
			setProductsToShow(products);
		} else {
			const filteredProducts = products.filter((item) =>
				item.node.productData.productMainCategory.includes(val)
			);

			setProductsToShow(filteredProducts);
		}
	};

	const getLink = (slug) => {
		const loc = getLocaleUrl();
		if (loc) return `/${loc}/products/${slug}`;
		return `/products/${slug}`;
	};

	return (
		<Box mt="48px" mx="10px">
			<Flex justifyContent="center">
				{mainSelects.map((item, i) => (
					<CustomRadio
						selected={selected}
						onChange={doFiltering}
						key={item}
						value={item}
						color={"#ffc600"}
					/>
				))}
			</Flex>

			<Grid
				templateColumns={["repeat(2, 50%)", "repeat(3, 1fr)"]}
				//templateColumns="repeat(auto-fill, minmax(200px, 350px))"
				mt={100}
				mx="auto"
				maxW={1200}
				borderTop="1px solid #484848"
				borderLeft="1px solid #484848"
			>
				{productsToShow.map(({ node }) => (
					<Box
						key={node.slug}
						py="1rem"
						px="1.5rem"
						textAlign="center"
						borderBottom="1px solid #484848"
						borderRight="1px solid #484848"
					>
						<StyledLink to={getLink(node.slug)}>
							<GatsbyImage
								image={
									node.productData.productImage.localFile
										.childImageSharp.gatsbyImageData
								}
								style={{
									maxWidth: "400px",
									maxHeight: "400px",
								}}
								imgStyle={{ objectFit: "contain" }}
							/>
							<Box mt="auto">
								<ProductTitle
									size={breakpoints.sm ? 1.15 : 1.5}
								>
									{node.productData.productNameHyphenated
										? node.productData.productNameHyphenated.replace(
												/-/g,
												"\u00AD"
										  )
										: node.productData.productName}
								</ProductTitle>
								<ProductSubTitle
									size={breakpoints.sm ? 1 : 1.125}
								>
									{node.productData.productSubtitle}
								</ProductSubTitle>
							</Box>
						</StyledLink>
					</Box>
				))}
			</Grid>
		</Box>
	);
}
